<template>
  <div class="agentList">
    <el-table :data="publishers" empty-text="No Data">
      <el-table-column prop="user_nickName" label="Agent"></el-table-column>
      <el-table-column>
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="small"
            style="background: #ec652b; border-color: #ec652b"
            @click="toChat(scope.row)"
            >Chat</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { getAllPublishers } from "../api/reff.js";
export default {
  name: "agentList",
  data() {
    return {
      publishers: [],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      getAllPublishers().then((res) => {
        if (res.data.code === 0) {
          this.publishers = res.data.data;
        }
      });
    },
    toChat(row) {
      this.$router.push({
        path: "/home/my-agent-service",
        query: {
          user_id: row._id.$id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.agentList {
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
}
</style>